/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Optional: Add any additional custom styles here */
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Lilita+One&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --brand-orange: #ff6600; /* Example color */
  --brand-orange-light: #ff8533; /* Lighter shade example */
  --brand-blue: #0066ff; /* Example color */
  --brand-blue-light: #3399ff; /* Lighter shade example */
}

h1 {
  font-family: 'Black Han Sans', sans-serif;
  font-size: 123px;
  
}
h2 {
  font-family: 'Black Han Sans', sans-serif;
  font-size: 93.2px;
  
}
h3 {
  font-family: 'Black Han Sans', sans-serif;
  font-size: 57.6px;
  
}
h4 {
  font-family: 'Raleway', sans-serif;
  font-size: 57.6px;
  font-weight: 600;
  
}
h5 {
  font-family: 'Raleway', sans-serif;
  font-size: 35.6px;
  font-weight: 500;
  
}

p {
  @apply text-base font-normal font-Raleway; /* Tailwind class for 16px font size and normal font weight */
  /* Override to set specific font size */
  font-size: 18px; /* Mobile size */
}

/* Tailwind's 'md' breakpoint for medium devices and up (default is 768px) */
@media (min-width: 768px) {
  p {
    @apply text-lg; /* Tailwind class for larger font size, typically 18px */
    /* Override if Tailwind's default 'text-lg' size does not match your design */
    font-size: 20px; /* Larger devices size */
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px; /* Adjust width to make the scrollbar thinner */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Remove the track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Change this to your preferred color */
  border-radius: 10px; /* Rounder edges for the scrollbar thumb */
  border: 2px solid transparent; /* Adds space around the thumb for floating effect */
}

body {
  padding-right: 10px; /* Adjust right padding to make room for the floating scrollbar */
  overflow: overlay; /* Ensure scrollbar is always visible and overlayed */
  margin: 0;
  padding: 0;
}

html {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* For Firefox */
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid transparent;
}


